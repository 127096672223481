<thead>
<tr class="text-center">
    <th>{{'PROMO-CODE.PROMO_CODE' | translate}}</th>
    <th>{{'PROMO-CODE.NUMBER_OF_BONUSES' | translate}}</th>
    <th>{{'PROMO-CODE.ONE_TIME_PROMO_CODE' | translate}}</th>
    <th>{{'PROMO-CODE.WHETHER_PROMO_CODE_IS_ACTIVE' | translate}}</th>
    <th>{{'PROMO-CODE.DATE_OF_PROMO_CODE_CREATION' | translate}}</th>
    <th>{{'PROMO-CODE.WHO_CREATED' | translate}}</th>
    <th>{{'PROMO-CODE.PHONE' | translate}}</th>
    <th>{{'PROMO-CODE.WHO_ACTIVATED' | translate}}</th>
    <th>{{'PROMO-CODE.PHONE' | translate}}</th>
</tr>
</thead>
<ng-container *ngFor="let object of promoCodeReport">
    <tbody>
    <tr class="text-center"  *ngFor="let promo of object.usages">
        <td>{{object.promocode}}</td>
        <td>{{object.amount}}</td>
        <td *ngIf="object.onetime === 1">{{'PROMO-CODE.YES' | translate}}</td>
        <td *ngIf="object.onetime === 0">{{'PROMO-CODE.NO' | translate}}</td>
        <td *ngIf="object.active === 1">{{'PROMO-CODE.YES' | translate}}</td>
        <td *ngIf="object.active === 0">{{'PROMO-CODE.NO' | translate}}</td>
        <td>{{object.created_at | date:'yyyy-MM-dd HH:mm:ss'}}</td>
        <td *ngIf="!object.referer">{{'PROMO-CODE.PROGRAMMATICALLY' | translate}}</td>
        <td *ngIf="!object.referer">-</td>
        <td *ngIf="object.referer">{{object.referer.name}}</td>
        <td *ngIf="object.referer">.{{object.referer.phone}} </td>
        <td>{{ promo.user.name }}</td>
        <td>.{{ promo.user.phone }}</td>
    </tr>
    </tbody>
</ng-container>
