import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Orders} from '../../quick-sale/all-quick-sale/interface';
import {tap} from 'rxjs/operators';
import {NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {AppApiUrls} from '../../app.api.urls';
import {RequestService} from '../../@shared/request.service';
import {HttpParams} from "@angular/common/http";

@Component({
    selector: 'app-report-quick-sale',
    templateUrl: './report-quick-sale.component.html',
    styleUrls: ['./report-quick-sale.component.scss']
})
export class ReportQuickSaleComponent implements OnInit {
    @Input() place;
    @Input() checkboxOrganizationId?;
    @Input() toDate: NgbDate | null;
    @Input() fromDate: NgbDate | null;
    constructor(public request: RequestService, private formatter: NgbDateParserFormatter) {
    }
    orders$: Observable<Orders[]>;
    sumPickup: number;
    sumPickupTerminal: number;
    sumDeliveryTerminal: number;
    sumDelivery: number;
    sumCourier: number;
    sumFull: number;
    sumDistrictPrice: number;
    sumSale: number;
    totalLiqPay: number;


    ngOnInit(): void {
        const params = (this.place == 'null') ? {
                from: this.formatter.format(this.fromDate),
                to: this.formatter.format(this.toDate),
                city_id: localStorage.getItem('city'),
                lastUpdatedAt: '',
                lastId: '',
                filters: null,
                checkbox_organization_id: Number(this.checkboxOrganizationId)
            }
            : {
                from: this.formatter.format(this.fromDate),
                to: this.formatter.format(this.toDate),
                place_id: this.place,
                city_id: localStorage.getItem('city'),
                lastUpdatedAt: '',
                lastId: '',
                filters: null,
                checkbox_organization_id: Number(this.checkboxOrganizationId)
            };
        const  data = {
            params
        };
        this.request.getRequest(AppApiUrls.routesSum, data).subscribe(json => {
            this.sumCourier = json.total_sum;
        }, () => {
        }, () => {
            const dataF = {
                params
            }
            this.orders$ = this.request.postRequest(AppApiUrls.GetAllOrders, dataF).pipe(tap(orders => {
                this.sumPickup =
                    orders.filter(json => json.pickupcheck == 1 && (json.status == 1 || json.status == 4) && json.terminalcheck == 0)
                        .reduce((a, b) => a + b.total, 0);
                this.totalLiqPay =
                    orders.filter(order => order.paid_status === 1)
                        .reduce((a, b) => a + b.total, 0);
                this.sumPickupTerminal =
                    orders.filter(json => json.pickupcheck == 1 && (json.status == 1 || json.status == 4) && json.terminalcheck !== 0)
                        .reduce((a, b) => a + b.total, 0);
                this.sumDelivery =
                    orders.filter(json => json.pickupcheck == 0 && (json.status == 1 || json.status == 4) && json.terminalcheck == 0)
                        .reduce((a, b) => a + b.total, 0);
                this.sumDeliveryTerminal =
                    orders.filter(json => json.pickupcheck == 0 && (json.status == 1 || json.status == 4) && json.terminalcheck !== 0)
                        .reduce((a, b) => a + b.total, 0);
                this.sumFull =
                    orders.filter(json => (json.status == 1 || json.status == 4))
                        .reduce((a, b) => a + b.total, 0);
                this.sumDistrictPrice =
                    orders.filter(json => (json.status == 1 || json.status == 4))
                        .reduce((a, b) => a + b.district_price, 0);
                this.sumSale =
                    orders.reduce((a, b) => a + Math.round((b.total / (100 - b.sale) * 100) * (b.sale / 100)), 0);
            }));
        });
    }

    ngOnDestroy = () => {
        delete this.orders$;
        delete this.sumPickup;
        delete this.sumPickupTerminal;
        delete this.sumDeliveryTerminal;
        delete this.sumDelivery;
        delete this.sumCourier;
        delete this.sumFull;
        delete this.sumDistrictPrice;
        delete this.totalLiqPay;
    }

}
