import {Cities} from '../../system/interface';

export interface Orders {
    id: number;
    order_number: number;
    phone: string;
    district_id: number;
    zone_id: number;
    street: string;
    house: string;
    attic: string;
    document_status: number;
    apt: string;
    floor: string;
    stick: string;
    stick_edu: string;
    terminal: number;
    terminalcheck: any;
    sale: number;
    pickupcheck: any;
    birthdaycheck: any;
    futurecheck: any;
    futuredate: string;
    futuretime: string;
    comment: string;
    author_id: string;
    total: number;
    totalwithoutsale: number;
    place: number;
    order: any;
    status: any;
    pickup_pay: number;
    district_price: number;
    place_name: string;
    author_name?: string;
    author_surname?: string;
    district?: District;
    author?: Author;
    cashierInfo: Author;
    duplicate?: boolean;
    paid_status: number;
    created_at?: string;
}

export interface DeliveryStatus {
    delivery_time: string;
    status: number;
    courier_name?: string;
    courier_phone?: string;
}

export interface ListeningBinotel {
    generalCallID: string;
    url: string;
    externalNumber: string;
    startTime: string;
    callType: string;
    disposition: string;
    billsec: string;
}

export interface CitiesCallCenter {
    id: number;
    name: string;
    cities: Cities[];
}

export interface District {
    id: number;
    name: string;
    place: number;
    price: number;
    city: number;
    pickup: number;
    city_zone: string;
}

export interface CourierTotalSum {
    total_sum: number;
}

export interface Author {
    id: number;
    name: string;
    surname: string;
}
export enum OrderStatus {
    Pending = 'pending',
    Done = 'done',
    Canceled = 'cancelled'
}

