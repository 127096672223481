<div class="form-group m-2">
    <button class="btn btn-outline-secondary btn-sm ml-1"
            (click)="chooseCollection(ChooseCollection)"
            [disabled]="!collectionId">{{'CASH_ACCOUNTING.COLLECTION' | translate}}</button>
    <button class="btn btn-outline-secondary btn-sm"
            [disabled]="collectionId" (click)="startShift()">{{'CLIENT_CASH_ACCOUNTING.JOIN_THE_SHIFT' | translate}}
    </button>
    <button class="btn btn-outline-secondary btn-sm  ml-1"
            [disabled]="!collectionId" (click)="endShift()">{{'CLIENT_CASH_ACCOUNTING.CLOSE_SHIFT' | translate}}
    </button>
    <button type="button" class="btn btn-outline-secondary btn-sm  ml-1" placement="bottom"
            [ngbPopover]="(mode !== 'pickup') ? CashInfoRL : CashInfo"
            [disabled]="!collectionId"
            (click)="getCashInfo()"
            popoverTitle="Інфо по кассі: ">
        {{'CLIENT_CASH_ACCOUNTING.INFO' | translate}}
    </button>
    <button class="btn btn-outline-secondary btn-sm ml-1"
            [disabled]="!collectionId" (click)="getBills(Collection, 'cashTransfer', 'cash')">{{'CLIENT_CASH_ACCOUNTING.CASH_TRANSFER' | translate}}
    </button>
</div>

<ng-template #Collection let-modal size="xl">
    <div class="modal-header">
        <h4 class="modal-title">{{'CASH_ACCOUNTING.COLLECTION' | translate}} </h4>
        <button #closebutton type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body no-padding">
        <ng-select class="m-2" *ngIf="buttonModal == 'cashTransfer'" [items]="shifts$ | async"
                   bindLabel="fullname"
                   bindValue="id"
                   notFoundText="{{'CLIENT_CASH_ACCOUNTING.NOT_FOUND' | translate}}"
                   placeholder="{{'CLIENT_CASH_ACCOUNTING.CHOOSE_WHO_TAKE_TRANSFER' | translate}}"
                   [(ngModel)]="nextCashier">
        </ng-select>
        <table class="table table-sm" style="text-align: center; width: 100%">
            <thead class="thead-light">
            <tr>
                <th scope="col">
                    {{'CASH_ACCOUNTING.BILLS' | translate}}
                </th>
                <th scope="col">
                    {{'CASH_ACCOUNTING.BILLS_COUNT' | translate}}
                </th>
            </tr>
            </thead>
            <ng-container *ngFor="let object of ClientCAService.bills;let i = index">
            <tr  style="vertical-align: middle" *ngIf="object.bill_type == billsType">
                <td>
                    {{object.bills}}
                </td>
                <td>
                    <input type="number" class="form-control custom-select-sm" [(ngModel)]="object.count"
                           (ngModelChange)="ClientCAService.sum(i)"
                           style="text-align: center">
                </td>
            </tr>
            </ng-container>
            <tr>
                <td>
                    {{'GLOBAL.SUM' | translate}}
                </td>
                <th scope="col">
                    {{ClientCAService.sumBills}}
                </th>
            </tr>
        </table>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-success" [disabled]="buttonModal == 'cashTransfer' && !nextCashier"
                (click)="(buttonModal == 'collection') ? collection() : cashTransfer(PrintCashTransfer)">{{'GLOBAL.BUTTONSAVE' | translate}}</button>
    </div>
</ng-template>








<ng-template #CashInfo>
    <div *ngIf="(cashInfo | async) as obs;  else Loading">
        <b>{{'CLIENT_CASH_ACCOUNTING.TOTAL_NOT_CASH_PICKUP' | translate}}</b>
        <p>{{obs.card}}</p>
        <b>{{'CLIENT_CASH_ACCOUNTING.TOTAL_CASH_PICKUP' | translate}}</b>
        <p>{{obs.cash}}</p>
        <b>{{'CLIENT_CASH_ACCOUNTING.TOTAL_PICKUP' | translate}}</b>
        <p>{{obs.card + obs.cash}}</p>
        <b>{{'CLIENT_CASH_ACCOUNTING.TOTAL_NOT_CASH_PAID_PICKUP' | translate}}</b>
        <p>{{obs.card_paid}}</p>
        <b>{{'CLIENT_CASH_ACCOUNTING.TOTAL_CASH_PAID_PICKUP' | translate}}</b>
        <p>{{obs.cash_paid}}</p>
        <b>{{'CLIENT_CASH_ACCOUNTING.TOTAL_PAID_PICKUP' | translate}}</b>
        <p>{{obs.cash_paid + obs.card_paid}}</p>
        <b>{{'CLIENT_CASH_ACCOUNTING.CASH_ACCEPTED' | translate}}</b>
        <p>{{obs.collection_done}}</p>
        <ng-container *ngIf="(cashFromBeforeCashier$ | async) as cash">
        <b>{{'CLIENT_CASH_ACCOUNTING.RECEIVED_AMOUNT' | translate}}</b>
        <p>{{ cash.cash || 0 }}</p>
        <b>{{'CLIENT_CASH_ACCOUNTING.CASH_BALANCE' | translate}}</b>
        <p>{{obs.cash_paid - obs.collection_done}}</p>
        </ng-container>
    </div>
</ng-template>

<ng-template #Loading>
    {{'CLIENT_CASH_ACCOUNTING.COUNT_CASH' | translate}}
    <span class="spinner-border spinner-border-sm text-success" role="status" aria-hidden="true"></span>
</ng-template>


<ng-template #PrintCashTransfer let-modal size="l">
    <div class="modal-header">
        <h4 class="modal-title">{{'CASH_ACCOUNTING.COLLECTION' | translate}} </h4>
        <button #closebutton type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body no-padding" id="report_cash_transfer" *ngIf="returnedInfo">
        <h3 class="text-center">{{'CLIENT_CASH_ACCOUNTING.ACT' | translate}}</h3>
        <h4 class="text-center">{{'CLIENT_CASH_ACCOUNTING.ACCEPTING_CASH_TRANSFER' | translate}}</h4>
        <h4 class="text-center">{{returnedInfo.data.created_at | date: 'd.MM.yyyy H:m:s'}}</h4>
        <p>{{'CLIENT_CASH_ACCOUNTING.ENTERPRISES' | translate}} {{returnedInfo.city_id.name_ua}}</p>
        <p>{{'CLIENT_CASH_ACCOUNTING.PLACES' | translate}} {{returnedInfo.place_info.name_ua}}</p>
        <p>{{'CLIENT_CASH_ACCOUNTING.CASHIER' | translate}} {{returnedInfo.data.current_collection_info.user_info.name}} {{returnedInfo.data.current_collection_info.user_info.surname}} передає,</p>
        <p>{{'CLIENT_CASH_ACCOUNTING.CASHIER' | translate}} {{returnedInfo.data.target_collection_info.user_info.name}} {{returnedInfo.data.target_collection_info.user_info.surname}} приймає:</p>
        <table class="table table-sm" style="text-align: center; width: 100%">
            <thead class="thead-light">
            <tr>
                <th scope="col">
                    {{'CASH_ACCOUNTING.BILLS' | translate}}
                </th>
                <th scope="col">
                    {{'CLIENT_CASH_ACCOUNTING.COUNT_BILLS' | translate}}
                </th>
                <th scope="col">
                    {{'GLOBAL.SUM' | translate}}
                </th>
            </tr>
            </thead>
            <tr *ngFor="let object of returnedInfo.data.transfer_bills;let i = index" style="vertical-align: middle">
                <td>
                    {{object.bill_rate}}
                </td>
                <td>
                   {{object.count}}
                </td>
                <td>
                    {{object.count * object.bill_rate}}
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    {{'GLOBAL.SUM' | translate}}
                </td>
                <th scope="col">
                    {{returnedInfo.data.transfer_bills | sumBills}}
                </th>
            </tr>
        </table>
        <p>{{'CLIENT_CASH_ACCOUNTING.PASSED' | translate}} {{returnedInfo.data.current_collection_info.user_info.name}} {{returnedInfo.data.current_collection_info.user_info.surname}} {{'CLIENT_CASH_ACCOUNTING.SIGNATURE' | translate}}</p>
        <p>{{'CLIENT_CASH_ACCOUNTING.ACCEPTED' | translate}} {{returnedInfo.data.target_collection_info.user_info.name}} {{returnedInfo.data.target_collection_info.user_info.surname}} {{'CLIENT_CASH_ACCOUNTING.SIGNATURE' | translate}}</p>
        <div class="w-100 d-none">
            <h3 class="text-center">{{'CLIENT_CASH_ACCOUNTING.ACT' | translate}}</h3>
            <h4 class="text-center">{{'CLIENT_CASH_ACCOUNTING.ACCEPTING_CASH_TRANSFER' | translate}}</h4>
            <h4 class="text-center">{{returnedInfo.data.created_at | date: 'd.MM.yyyy H:m:s'}}</h4>
            <p>{{'CLIENT_CASH_ACCOUNTING.ENTERPRISES' | translate}} {{returnedInfo.city_id.name_ua}}</p>
            <p>{{'CLIENT_CASH_ACCOUNTING.PLACES' | translate}} {{returnedInfo.place_info.name_ua}}</p>
            <p>{{'CLIENT_CASH_ACCOUNTING.CASHIER' | translate}} {{returnedInfo.data.current_collection_info.user_info.name}} {{returnedInfo.data.current_collection_info.user_info.surname}} передає,</p>
            <p>{{'CLIENT_CASH_ACCOUNTING.CASHIER' | translate}} {{returnedInfo.data.target_collection_info.user_info.name}} {{returnedInfo.data.target_collection_info.user_info.surname}} приймає:</p>
            <table class="table table-sm" style="text-align: center; width: 100%">
                <thead class="thead-light">
                <tr>
                    <th scope="col">
                        {{'CASH_ACCOUNTING.BILLS' | translate}}
                    </th>
                    <th scope="col">
                        {{'CLIENT_CASH_ACCOUNTING.COUNT_BILLS' | translate}}
                    </th>
                    <th scope="col">
                        {{'GLOBAL.SUM' | translate}}
                    </th>
                </tr>
                </thead>
                <tr *ngFor="let object of returnedInfo.data.transfer_bills;let i = index" style="vertical-align: middle">
                    <td>
                        {{object.bill_rate}}
                    </td>
                    <td>
                        {{object.count}}
                    </td>
                    <td>
                        {{object.count * object.bill_rate}}
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        {{'GLOBAL.SUM' | translate}}
                    </td>
                    <th scope="col">
                        {{returnedInfo.data.transfer_bills | sumBills}}
                    </th>
                </tr>
            </table>
            <p>{{'CLIENT_CASH_ACCOUNTING.PASSED' | translate}} {{returnedInfo.data.current_collection_info.user_info.name}} {{returnedInfo.data.current_collection_info.user_info.surname}} {{'CLIENT_CASH_ACCOUNTING.SIGNATURE' | translate}}</p>
            <p>{{'CLIENT_CASH_ACCOUNTING.ACCEPTED' | translate}} {{returnedInfo.data.target_collection_info.user_info.name}} {{returnedInfo.data.target_collection_info.user_info.surname}} {{'CLIENT_CASH_ACCOUNTING.SIGNATURE' | translate}}</p>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-success"
                ngxPrint
                printTitle="{{'GLOBAL.PRINT_REPORT' | translate}}"
                printSectionId="report_cash_transfer"
                [printStyle]="{ h3: { 'text-align': 'center' }, h4: { 'text-align': 'center' },
                 table: {'border-collapse': 'collapse'}, td: {'border': '1px solid black'},
                  th: {'border': '1px solid black'}}"
                [useExistingCss]="false">{{'GLOBAL.PRINT' | translate}}</button>
    </div>
</ng-template>

<ng-template #CashInfoRL>
    <div *ngIf="(cashInfo | async) as obs;  else Loading">
        <b>{{'CLIENT_CASH_ACCOUNTING.TOTAL_NOT_CASH' | translate}}</b>
        <p>{{obs.orders_card}}</p>
        <b>{{'CLIENT_CASH_ACCOUNTING.TOTAL_CASH' | translate}}</b>
        <p>{{obs.orders_cash}}</p>
        <b>{{'CLIENT_CASH_ACCOUNTING.TOTAL_DELIVERY' | translate}}</b>
        <p>{{obs.delivery}}</p>
        <b>{{'CLIENT_CASH_ACCOUNTING.TOTAL_SUM' | translate}}</b>
        <p>{{obs.orders_card + obs.orders_cash + obs.delivery}}</p>
        <b>{{'CLIENT_CASH_ACCOUNTING.TOTAL_NOT_CASH_PAID' | translate}}</b>
        <p>{{obs.orders_card_paid  + obs.orders_card_debt}}</p>
        <b>{{'CLIENT_CASH_ACCOUNTING.TOTAL_CASH_PAID' | translate}}</b>
        <p>{{obs.orders_cash_paid - obs.delivery_paid  + obs.orders_cash_debt}}</p>
        <b>{{'CLIENT_CASH_ACCOUNTING.TOTAL_PAID_DELIVERY' | translate}}</b>
        <p>{{obs.delivery_paid}}</p>
        <b>{{'CLIENT_CASH_ACCOUNTING.TOTAL_PAID' | translate}}</b>
        <p>{{obs.orders_card_paid + obs.orders_cash_paid + obs.orders_card_debt  + obs.orders_cash_debt}}</p>
        <b>{{'CLIENT_CASH_ACCOUNTING.CASH_ACCEPTED' | translate}}</b>
        <p>{{obs.collection_done}}</p>
        <b>{{'CLIENT_CASH_ACCOUNTING.TOTAL_DEBTS' | translate}}</b>
        <p>{{obs.orders_cash_debt}}</p>
        <ng-container *ngIf="(cashFromBeforeCashier$ | async) as cash">
            <b>{{'CLIENT_CASH_ACCOUNTING.RECEIVED_AMOUNT' | translate}}</b>
            <p>{{ cash.cash || 0 }}</p>
            <b>{{'CLIENT_CASH_ACCOUNTING.CASH_BALANCE' | translate}}</b>
            <p>{{obs.orders_cash_paid  - obs.delivery_paid - obs.collection_done - obs.orders_cash_debt }}</p>
        </ng-container>
    </div>
</ng-template>

<ng-template #ChooseCollection let-modal size="xl">
    <div class="modal-header">
        <button #closebutton type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
              <button class="btn btn-warning w-100 my-2" (click)="getBills(Collection, 'collection', 'card')">
                  {{'CLIENT_CASH_ACCOUNTING.COLLECTION_CARD' | translate}}
              </button>

              <button class="btn btn-primary w-100" (click)="getBills(Collection, 'collection', 'cash')">
                  {{'CLIENT_CASH_ACCOUNTING.COLLECTION_MONEY' | translate}}
              </button>
    </div>
    <div class="modal-footer">

    </div>
</ng-template>
