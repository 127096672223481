import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AppApiUrls} from '../../app.api.urls';
import {Alltoasts} from '../../toasts/alltoasts';
import {Observable} from 'rxjs';
import {NewsData, NewsDataInfo} from "../../notification/interface/notification.interface";


@Injectable({providedIn: 'root'})

export class NavbarService {

    public allModules: any[] = [];
    public AllPlaces: any[] = [];

    constructor(private http: HttpClient, private allToasts: Alltoasts) {

    }

    getCityInfo(): Observable<any> {
        if (localStorage.getItem('city') != null)
        {
            return this.http.get<any>(AppApiUrls.CityInfo(), {
                params: {
                    city_id: localStorage.getItem('city'),
                    lang: localStorage.getItem('lang'),
                }
            });
        }
    }

    fetchModels(): Observable<any>{
        return this.http.get<any>(AppApiUrls.GetAllModules(),
            {
                params: {
                   prof: localStorage.getItem('profs'),
                   city: localStorage.getItem('city')
                }
            });
    }

    getAllPlaces = () => {
        return this.http.get<any>(AppApiUrls.GetAllPlaces)
            .subscribe(
                (val) => {
                    this.AllPlaces = val as any;
                },
                response => {
                    console.log('POST call in error', response);
                    this.allToasts.showError();
                },
                () => {

                });
    } // TODO убрать получение отделения оно есть в сити сервисе

    changePlace = place => {
        const id = localStorage.getItem('id');
        const body = new HttpParams()
            .set('place', place);
        return this.http.put<any>(AppApiUrls.getAllUsers() + id,
            body)
            .subscribe(
                (val) => {
                    localStorage.setItem('place', place);
                    localStorage.setItem('city', val.city_id);
                    localStorage.setItem('type', val.check_type);
                    localStorage.setItem('city-name', val.name_ua);

                },
                response => {
                    console.log('POST call in error', response);
                    this.allToasts.showError();
                },
                () => {
                    window.location.reload();
                });
    }

    getNews(): Observable<NewsData> {
        return this.http.get<NewsData>(AppApiUrls.news(), { params: {
            user_id: localStorage.getItem('id')
        }});
    }

    getNewsByID(id: number): Observable<NewsDataInfo> {
        return this.http.get<NewsDataInfo>(AppApiUrls.news() + '/' + id);
    }

    checkedNews(body: NewsData ): Observable<NewsData> {
        return this.http.post<NewsData>(AppApiUrls.newsConfirmation(), body);
    }

}

