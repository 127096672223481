<div class="container-fluid">
    <div class="row" style="margin-top: 1rem">
        <div class="col-sm-7">
            <form [formGroup]="form" class="is-invalid">
                <div class="form-row">
                    <div class="form-group col-sm-4">
                        <div class="input-group input-group-xs">
                            <div class="input-group-prepend">
                                <a href="tel:{{form.value.phone}}" target="_self" class="btn btn-outline-success"
                                   id="button-phone">{{'REPORT_EDITED_HISTORY.PHONE' | translate}}</a>
                            </div>
                            <input type="text" class="form-control" (change)="fillByPhone(true)" formControlName="phone"
                                   id="phone"
                                   placeholder="{{'GLOBAL.PHONE' | translate}}" autocomplete="off"
                                   [ngClass]="{'is-invalid': form.get('phone').invalid, 'is-valid': form.get('phone').valid}">
                            <div class="input-group-append">
                                <button class="btn btn-outline-success" type="button"
                                        id="button-personnel" (click)="personnelEnable()"
                                        [ngClass]="{'btn-success': form.value.personnel == true}"
                                        [disabled]="mode=='edit'">{{'QUICK-SALE.PERSONNEL' | translate}}</button>
                            </div>
                        </div>
                        <small class="form-text text-danger"
                               *ngIf="form.get('phone').invalid && form.get('phone').touched ">
                            <small class="form-text text-danger"
                                   *ngIf="form.get('phone').errors['required']">
                                {{'QUICK-SALE.VALID_PHONE_FIRST' | translate}}
                            </small>
                            <small class="form-text text-danger"
                                   *ngIf="form.get('phone').errors['minlength'] && form.get('phone').errors['minlength']['requiredLength']">
                                {{'QUICK-SALE.VALID_PHONE_SECOND' | translate}} {{form.get('phone').errors['minlength']['actualLength']}}
                                *
                            </small>
                        </small>
                    </div>
                    <div class="form-group col-sm-3">
                        <div class="input-group input-group-xs">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{'QUICK-SALE.DISTRICT' | translate}}</span>
                            </div>
                            <select class="form-control" id="district" formControlName="district"
                                    [ngClass]="{'is-invalid': form.get('district').invalid, 'is-valid': form.get('district').valid}">
                                <option *ngFor="let object of DocService.districts; i as index"
                                        [value]="object.id">{{object.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group col-sm-2">
                        <div class="input-group input-group-xs">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{'QUICK-SALE.ZONE' | translate}}</span>
                            </div>
                            <select class="form-control" id="zone" formControlName="zone"
                                    [ngClass]="{'is-invalid': form.get('zone').invalid, 'is-valid': form.get('zone').valid}">
                                <option *ngFor="let object of DocService.zones;let i = index"
                                        [value]="object.id">{{object.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-sm-5">

                        <div class="input-group input-group-xs">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{'QUICK-SALE.STREET' | translate}}</span>
                            </div>
                            <input type="text" class="form-control custom-select-sm" id="street"
                                   placeholder="{{'QUICK-SALE.STREET' | translate}}" autocomplete="off"
                                   formControlName="street"
                                   [ngClass]="{'is-invalid': form.get('street').invalid, 'is-valid': form.get('street').valid}">
                        </div>
                    </div>
                    <div class="form-group col-sm-1">
                        <div class="input-group input-group-xs">

                            <input type="text" class="form-control custom-select-sm" id="house"
                                   placeholder="{{'QUICK-SALE.HOUSE' | translate}}" autocomplete="off"
                                   formControlName="house"
                                   [ngClass]="{'is-invalid': form.get('house').invalid, 'is-valid': form.get('house').valid}"
                            >
                        </div>
                    </div>
                    <div class="form-group col-sm-1">
                        <div class="input-group input-group-xs">

                            <input type="text" class="form-control custom-select-sm" id="attic"
                                   placeholder="{{'QUICK-SALE.ATTIC' | translate}}" autocomplete="off"
                                   formControlName="attic"
                                   [ngClass]="{'is-invalid': form.get('attic').invalid && form.get('attic').touched, 'is-valid': form.get('attic').valid && form.get('attic').touched}">
                        </div>
                    </div>
                    <div class="form-group col-sm-1">
                        <div class="input-group input-group-xs">

                            <input type="text" class="form-control custom-select-sm" id="floor"
                                   placeholder="{{'QUICK-SALE.FLOOR' | translate}}" autocomplete="off"
                                   formControlName="floor"
                                   [ngClass]="{'is-invalid': form.get('floor').invalid && form.get('floor').touched, 'is-valid': form.get('floor').valid && form.get('floor').touched}">
                        </div>
                    </div>
                    <div class="form-group col-sm-1">
                        <div class="input-group input-group-xs">

                            <input type="text" class="form-control custom-select-sm" id="apartment"
                                   placeholder="{{'QUICK-SALE.APARTMENT' | translate}}" autocomplete="off"
                                   formControlName="apt"
                                   [ngClass]="{'is-invalid': form.get('apt').invalid && form.get('apt').touched, 'is-valid': form.get('apt').valid && form.get('apt').touched}">
                        </div>
                    </div>
                    <div class="form-group col-sm-3">
                        <a [ngClass]="{'btn-dark': giftCount != 0}" href="/reports/winner-list" target="_blank" class="btn btn-dark">
                            {{'QUICK_SALE_DOCUMENT.CLIENTS_PRESENTS' | translate}}: {{giftCount}}</a>
                    </div>
                </div>
                    <div class="form-group default-address col-sm-5 d-flex" *ngIf="mode === 'site' && DocService.lastAddress " >
                        <div>
                            <span class="small">{{'QUICK-SALE.STREET' | translate}}: </span>
                            <span class="small font-weight-bold">{{ DocService.lastAddress?.street }}&nbsp; </span>
                        </div>
                        <div>
                            <span class="small"> {{'QUICK-SALE.HOUSE' | translate}}: </span>
                            <span class="small font-weight-bold">{{ DocService.lastAddress?.house }}&nbsp; </span>
                        </div>
                        <div>
                            <span class="small"> {{'QUICK-SALE.ATTIC' | translate}} </span>
                            <span class="small font-weight-bold">{{ DocService.lastAddress?.attic }}&nbsp; </span>
                        </div>
                        <div>
                            <span class="small"> {{'QUICK-SALE.FLOOR' | translate}} </span>
                            <span class="small font-weight-bold">{{ DocService.lastAddress?.floor }}&nbsp; </span>
                        </div>
                        <div>
                            <span class="small"> {{'QUICK-SALE.APARTMENT' | translate}} </span>
                            <span class="small font-weight-bold">{{ DocService.lastAddress?.apt }}&nbsp; </span>
                        </div>
                        <button class=" btn btn-sm btn-success font-size-arrow ml-2 text-center" (click)="autosubstitution()"> ➔</button>
                    </div>

                <div class="form-row">
                    <div class="form-group col-sm-4">

                        <div class="input-group input-group-xs">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{'QUICK-SALE.STICKS' | translate}}</span>
                                <span class="input-group-text">{{'QUICK-SALE.ORDINARY' | translate}}</span>
                            </div>
                            <input type="text" class="form-control custom-select-sm" id="ordinarySticks"
                                   placeholder="{{'QUICK-SALE.ORDINARY' | translate}}" autocomplete="off"
                                   formControlName="ord"
                                   [ngClass]="{'is-invalid': form.get('ord').invalid, 'is-valid': form.get('ord').valid}">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{'QUICK-SALE.EDUCATION' | translate}}</span>
                            </div>
                            <input type="text" class="form-control custom-select-sm" id="educationSticks"
                                   placeholder="{{'QUICK-SALE.EDUCATION' | translate}}" autocomplete="off"
                                   formControlName="edu"
                                   [ngClass]="{'is-invalid': form.get('edu').invalid, 'is-valid': form.get('edu').valid}">
                        </div>
                    </div>
                    <div class="form-group col-sm-4">
                        <div class="input-group input-group-xs">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{'GLOBAL.PLACE' | translate}}</span>
                            </div>
                            <select class="form-control" id="place" formControlName="place"
                                    [ngClass]="{'is-invalid': form.get('place').invalid , 'is-valid': form.get('place').valid }">
                                <option *ngFor="let object of DocService.places; i as index"
                                        [value]="object.id">{{object.name}}</option>
                            </select>
                        </div>
                    </div>
                    <ng-container *ngIf="blackList != undefined">
                        <div class="form-group col-sm-4" *ngIf="blackList.data != null">
                            <span style="font-size: 12px; font-weight: bold;color: red;">{{'QUICK_SALE_DOCUMENT.PHONE_IN_BLACK_LIST' | translate}}</span>
                        </div>
                    </ng-container>
                </div>


                <div class="form-row">
                    <div class="form-group col-sm-4">

                        <div class="input-group input-group-xs">
                            <div class="input-group-prepend">
                                <label class="btn btn-outline-success"
                                       ngbButtonLabel>{{'QUICK-SALE.CASHLESS' | translate}}
                                    <input type="checkbox" ngbButton id="terminal_check"
                                           [(ngModel)]="form.value.terminal_check"
                                           (change)="switchTerminal()"
                                           [ngModelOptions]="{standalone: true}"
                                    >
                                </label>
                            </div>
                            <select class="form-control" id="cashless" formControlName="terminal"
                                    [ngClass]="{'is-valid': form.get('terminal').valid,'is-invalid': form.get('terminal').invalid}">
                                <option *ngFor="let object of terminals$ | async; i as index"
                                        [value]="object.id">{{object.tname}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group col-sm-2">
                        <div class="input-group input-group-xs">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{'QUICK-SALE.SALE' | translate}}</span>
                            </div>
                            <input pattern="[0-9]+" type="text" class="form-control custom-select-sm" id="sale"
                                   placeholder="{{'QUICK-SALE.SALE' | translate}}" autocomplete="off"
                                   formControlName="sale" (change)="changeSalePercent()"
                                   [readOnly]="mode === 'edit'"
                                   [ngClass]="{'is-valid': form.get('sale').valid,'is-invalid': form.get('sale').invalid}">
                        </div>
                    </div>

                    <div class="form-group col-sm-2">
                        <label class="btn btn-outline-secondary btn-sm" style="width: 100%" ngbButtonLabel>
                            {{'QUICK-SALE.PICKUP' | translate}} <input type="checkbox" ngbButton
                                                                       (change)="checkedCheckbox($event, 'pickup')"
                                                                       [(ngModel)]="form.value.pickup_check"
                                                                       [disabled]="form.value.personnel == true || mode=='edit'"
                                                                       [checked]="form.value.personnel == true"
                                                                       [ngModelOptions]="{standalone: true}"
                                                                       style="float: right">
                        </label>
                    </div>

                    <div class="form-group col-sm-2">
                        <label class="btn btn-outline-secondary btn-sm" style="width: 100%" ngbButtonLabel>
                            {{'QUICK-SALE.H/B' | translate}} <input type="checkbox" ngbButton
                                                                    (change)="checkedCheckbox($event, 'birthday')"
                                                                    [disabled]="form.value.personnel == true || mode=='edit'"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    [(ngModel)]="form.value.birthday_check"
                                                                    style="float: right">
                        </label>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-sm-5">
                        <div class="input-group input-group-xs">


                            <div class="input-group-prepend">
                                <label class="btn btn-outline-success"
                                       ngbButtonLabel>{{'QUICK-SALE.PRE-ORDER' | translate}}
                                    <input type="checkbox" ngbButton id="pre_order"
                                           [checked]="form.get('pre_order_check').value"
                                           (change)="preOrder()"
                                           [(ngModel)]="form.value.pre_order_check"
                                           [ngModelOptions]="{standalone: true}"
                                    >
                                </label>
                            </div>
                             <input *ngIf="mode == 'new' || mode == 'copy'  || mode == 'site'" (keydown)="onlyFutureDate($event)" type="datetime-local"
                                           [ngClass]="{'is-valid': form.get('datetime').valid,'is-invalid': form.get('datetime').invalid}"
                                           formControlName="datetime" class="form-control"
                                           min="{{formatter.format(calendar.getToday())}}T00:00">
                                    <input *ngIf="mode != 'new' && mode != 'copy' && mode != 'site'" (keydown)="onlyFutureDate($event)" disabled type="datetime-local"
                                           [ngClass]="{'is-valid': form.get('datetime').valid,'is-invalid': form.get('datetime').invalid}"
                                           formControlName="datetime" class="form-control"
                                           min="{{DocService.infoOrder?.futuredate}}T00:59"
                                           max="{{DocService.infoOrder?.futuredate}}T00:59">
                        </div>

                    </div>
                    <div class="form-group col-sm-2">
                        <div class="input-group input-group-xs">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Бонуси</span>
                            </div>
                            <input pattern="[0-9]+" type="text" class="form-control custom-select-sm" id="bonuses"
                                   placeholder="Бонуси" autocomplete="off"
                                   formControlName="bonuses"
                                   (change)="changeBonusPercent()"
                                   [readOnly]="!DocService?.infoOrder?.site_user_id || mode === 'edit'"
                                   [ngClass]="{'is-valid': form.get('bonuses').valid,'is-invalid': form.get('bonuses').invalid}">
                        </div>
                    </div>
                    <div class="form-group col-sm-3">
                        <span style="font-size: 12px; font-weight: bold;color: #495057;">{{'QUICK_SALE_DOCUMENT.DELIVERY_TIME' | translate}}: {{DocService.deliveryTime}}</span>
                    </div>
                    <ng-container *ngIf="lastBirthday != undefined">
                    <div class="form-group col-sm-4" *ngIf="lastBirthday.birthdaycheck != 0">
                        <span style="font-size: 12px; font-weight: bold;color: red;">{{'QUICK_SALE_DOCUMENT.LAST_BD' | translate}}: {{lastBirthday.futuredate}}
                            Чек № {{lastBirthday.order_number}}</span>
                    </div>
                    </ng-container>
                </div>
                <div class="form-row">
                    <div class="form-group  col-sm-12" style="height: 35vh; overflow-y: scroll">
                        <table class="table table-sm" style="width: 100%;">
                            <thead class="thead-light">
                            <tr>
                                <th scope="col">{{'GLOBAL.TITLE' | translate}}</th>
                                <th scope="col">{{'GLOBAL.COUNT' | translate}}</th>
                                <th scope="col">{{'GLOBAL.PRICE' | translate}}</th>
                                <th scope="col">{{'QUICK-SALE.SALE' | translate}}</th>
                                <th scope="col">{{'GLOBAL.SUM' | translate}}</th>
                                <th scope="col">{{'GLOBAL.ACTION' | translate}}</th>
                            </tr>
                            </thead>
                            <ng-container *ngFor="let object of DocService.bodyOrder;let i = index">
                                <tr [ngClass]="{'alert-success': selectedIndex === i}">
                                    <td class="font-weight-bold" [ngStyle]="{'color': object.part_of_set === 1 ? 'red' : 'initial'}">{{object.product_name}}</td>
                                    <td>
                                        <div class="input-group input-group-xs">
                                            <div class="input-group-prepend">
                                                <button class="btn btn-outline-success" type="button"
                                                        (click)="removeFromQuantity(i)"
                                                        [disabled]="mode == 'edit' || object.part_of_set === 1"
                                                >X
                                                </button>
                                            </div>
                                            <input type="number" class="form-control custom-select-sm"
                                                   [(ngModel)]="object.quantity"
                                                   (ngModelChange)="changeQuantity(i)" [ngModelOptions]="{standalone: true}"
                                                   style="text-align: center" autocomplete="off"
                                                   [disabled]="mode == 'edit'|| object.part_of_set === 1"
                                            >
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-success" type="button"
                                                        (click)="addToQuantity(i)"
                                                        [disabled]="mode == 'edit' || object.part_of_set === 1"
                                                >+
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{object.price | number: '1.2-2'}} {{currency}}</td>
                                    <td><input type="number" step="0.01" mask="99999999999999.00" class="form-control custom-select-sm"
                                               [(ngModel)]="object.sale"  [ngModel]="object.sale | number: '1.2-2'"
                                               [ngModelOptions]="{standalone: true}"
                                               (ngModelChange)="changeSale(i)"
                                               [readOnly]="mode == 'edit'"
                                               style="text-align: center" autocomplete="off" [disabled]="object.part_of_set === 1"></td>
                                    <td >{{object.sum | number: '1.2-2' }} </td>
                                    <td class="d-flex flex-column align-items-center" >
                                        <div class="d-flex justify-content-between mb-2">
                                            <button class="btn btn-success btn-circle btn-sm edit-btn mr-2"
                                                    [disabled]="mode == 'edit'"
                                                    (click)="getLinkedProducts(object, i)"
                                            >&#9998;</button>

                                            <button type="button" class="btn btn-danger btn-circle btn-sm ml-2"
                                                    (click)="deleteProductFromCard(i)"
                                                    [disabled]="mode == 'edit'"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                     fill="currentColor"
                                                     class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd"
                                                          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
                                                </svg>
                                            </button>
                                        </div>
                                            <button *ngIf="object?.confirmed === false" type="button" class="btn btn-primary btn-circle btn-sm" (click)="activateWinnerList(object, object.gift_id)"> {{ 'WINNER_LIST.ACTIVATE'  | translate}} </button>

                                    </td>
                                </tr>
                                <ng-container *ngIf="object.childs && object.childs.length > 0">
                                    <tr *ngFor="let child of object.childs; let j = index ">
                                        <td class="pl-5" >
                                            {{ child?.product_name }}
                                        </td>
                                        <td>
                                            <div class="input-group input-group-xs">
                                                <div class="input-group-prepend">
                                                    <button class="btn btn-outline-success" type="button"
                                                            (click)="removeFromQuantityCardOrderParts(j, object)"
                                                            [disabled]="mode == 'edit' || child.part_of_set === 1"
                                                    >X
                                                    </button>
                                                </div>
                                                <input type="number" class="form-control custom-select-sm"
                                                       [(ngModel)]="child.quantity"
                                                       (ngModelChange)="changeQuantityCardsOfParts(j, object)" [ngModelOptions]="{standalone: true}"
                                                       style="text-align: center" autocomplete="off"
                                                       [disabled]="mode == 'edit'|| child.part_of_set === 1"
                                                >
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-success" type="button"
                                                            (click)="addToQuantityCardOrderParts(j, object)"
                                                            [disabled]="mode == 'edit' || child.part_of_set === 1"
                                                    >+
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{{child.price | number: '1.2-2'}} {{currency}}</td>
                                        <td><input type="number" step="0.01" mask="99999999999999.00" class="form-control custom-select-sm"
                                                   [(ngModel)]="child.sale"  [ngModel]="child.sale | number: '1.2-2'"
                                                   [ngModelOptions]="{standalone: true}"
                                                   (ngModelChange)="changeSale(i)"
                                                   [readOnly]="mode == 'edit'"
                                                   style="text-align: center" autocomplete="off" [disabled]="child.part_of_set === 1"></td>
                                        <td >{{child.sum | number: '1.2-2' }} </td>
                                        <td class="d-flex justify-content-around">
                                            <div class="margin-3"></div>

                                            <button type="button" class="btn btn-danger btn-circle btn-sm"
                                                    (click)="deleteProductParts(j, object)"
                                                    [disabled]="mode == 'edit' || selectedIndex !== i"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                     fill="currentColor"
                                                     class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd"
                                                          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>

                        </table>
                    </div>
                </div>

                <hr>
                <p>{{'QUICK_SALE_DOCUMENT.FREE_PRODUCT' | translate}}</p>
                <div class="d-flex justify-content-between my-3">
                    <div class="free-product" *ngFor="let object of DocService.freeProducts">
                        <span>{{object.product_name}} | </span>
                        <span>{{'QUICK_SALE_DOCUMENT.TOTAL_PRODUCTS' | translate}}: {{object.quantity}}</span>
                    </div>
                </div>
                <hr>
                <div class="form-row">
                    <div class="form-group col-sm-6">
                        <p>{{'QUICK_SALE_DOCUMENT.TOTAL_PRODUCTS' | translate}}:</p>
                    </div>

                    <div class="form-group col-sm-6">
                        <p style="float: right">{{DocService.count}}</p>
                    </div>
                </div>
                <hr>
                <div class="form-row">
                    <div class="form-group col-sm-6">
                        <p>{{'GLOBAL.SUM' | translate}}</p>
                    </div>

                    <div class="form-group col-sm-6">
                        <p style="float: right">{{DocService.sum | number: '1.2-2'}} {{currency}}</p>
                    </div>
                </div>


                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <textarea class="form-control" formControlName="comment"
                                  placeholder="{{'GLOBAL.COMMENT' | translate}}" id="comments"
                                  style="height: 10vh"></textarea>
                    </div>
                </div>
                <hr>
            </form>
            <div class="form-row">
                <div class="form-group col-sm-4">
                    <button class="btn btn-outline-success" id="send"
                            (click)="(mode === 'edit') ? actionOrder('put') : actionOrder('insert',null, receipt)"
                            [disabled]="form.invalid || !DocService.bodyOrder.length  || hasUnconfirmedItems()">{{'GLOBAL.SEND' | translate}}</button>
                </div>
                <div class="form-group col-sm-4 text-center">
                    <button class="btn btn-primary h-100" id="sort" (click)="SortBy()">
                        {{'QUICK_SALE_DOCUMENT.SORT' | translate}}
                    </button>
                </div>
                <div class="form-group col-sm-4">
                    <button class="btn btn-danger" style="float: right" id="closed" (click)="closeOrder()">{{'QUICK_SALE.CLOSE' | translate}}
                    </button>
                </div>
            </div>
        </div>
        <div class="col-sm-5">
            <span style="font-size: 12px; font-weight: bold;color: #495057;">{{'QUICK_SALE_DOCUMENT.MISSING_ING' | translate}}: {{DocService.kitchenComment}}</span>
            <div class=" mt-2 form-row">

                <div class="form-group col-sm-3">
                    <button class="btn btn-sm btn-outline-success" style="width: 100%" (click)="changeCatId(null)"
                            [ngClass]="{'btn-success': DocService.catId == 0}"
                            [disabled]="mode == 'edit'"
                    >Всі
                    </button>
                </div>
                <div class="form-group col-sm-3" *ngFor="let object of DocService.allCat; i as index">
                    <button class="btn btn-sm btn-outline-success h-100" (click)="changeCatId(object.id)"
                            [disabled]="mode == 'edit'"
                            [ngClass]="{'btn-success': object.id == DocService.catId}"
                            style="width: 100%">{{object.name}}</button>
                </div>

                <div class="form-group col-sm-12">
                    <div class="input-group input-group-xs">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{'GLOBAL.SEARCH' | translate}}</span>
                        </div>
                        <input type="text" [(ngModel)]="inputSearch" class="form-control" id="search"
                               [disabled]="mode == 'edit'"
                               placeholder="{{'GLOBAL.SEARCH' | translate}}" autocomplete="off">
                    </div>
                </div>
            </div>
                <ng-container *ngIf="constructorProducts">
                    <div class="d-flex flex-column" style="max-height: 60vh; overflow-y: scroll; overflow-x: hidden; padding: .3rem">

                        <ng-container *ngIf="linkedProducts?.length === 0">
                            <span class="font-weight-bold">Немає допів</span>
                        </ng-container>
                        <ng-container *ngIf="linkedProducts?.length > 0">
                            <ng-container *ngFor="let object of linkedProducts;let i = index">
                            <span class="my-2">
                                {{object.lang.description}}
                            </span>
                                <ng-container>
                                    <div class="form-row" [ngClass]="{'col-sm-12': inputSearch}">
                                        <div class="form-group col-sm-3" *ngFor="let prod of object.products | QuickSaleSearchPipe:inputSearch let i = index">
                                            <button class="btn btn-sm btn-outline-secondary h-100 m-1"
                                                    style="width: 100%;-moz-box-shadow:    3px 3px 5px 6px #ccc;
                                        -webkit-box-shadow: 3px 3px 5px 6px #ccc;
                                        box-shadow:         3px 3px 5px 6px #ccc;" (click)="addToPartsOrder(prod, prod.id, i, object)"
                                                    [disabled]="mode == 'edit' || (object.required === 1 && disabledProducts.includes(object.id))"
                                            >{{prod.name}}</button>
                                        </div>
                                    </div>
                                </ng-container>

                            </ng-container>

                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="!constructorProducts">
                    <div class="form-row" style="max-height: 60vh; overflow-y: scroll; overflow-x: hidden; padding: .3rem">
                        <div class="form-group col-sm-3"
                             *ngFor="let object of DocService.allProduct  | QuickSaleSearchPipe:inputSearch;let i = index"
                             [ngClass]="{'col-sm-12': inputSearch}">
                            <button class="btn btn-sm btn-outline-secondary h-100"
                                    style="width: 100%;-moz-box-shadow:    3px 3px 5px 6px #ccc;
                                    -webkit-box-shadow: 3px 3px 5px 6px #ccc;
                                        box-shadow:         3px 3px 5px 6px #ccc;" (click)="addToOrder(object.id)"
                                    [disabled]="mode == 'edit'"
                            >{{object.name}}</button>
                        </div>
                    </div>
                </ng-container >
        </div>
    </div>
</div>

<ng-template #receipt let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-recipt orderId="{{orderId}}"></app-recipt>
    </div>
    <div class="modal-footer">
        <button
                class="btn btn-outline-success"
                ngxPrint
                printTitle="{{'GLOBAL.PRINT_REPORT' | translate}}"
                printSectionId="receipt"
                [useExistingCss]="false"
        >{{'GLOBAL.PRINT' | translate}}</button>
    </div>
</ng-template>
<div class="loading" *ngIf="loader === true">Loading&#8230;</div>

<ng-template #DontCallNotice let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{'QUICK_SALE_DOCUMENT.DONT_CALL' | translate}}</h4>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary btn-sm"
                (click)="modal.close()">{{'GLOBAL.APPLY' | translate}}</button>
    </div>
</ng-template>

<ng-template #NotEnoughBonuses let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{'BONUSES.NOT_ENOUGH_BONUSES' | translate}}!</h4>
        <h4>{{'BONUSES.HE_HAS' | translate}}: {{maxBonuses}}</h4>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary btn-sm"
                (click)="modal.close()">{{'GLOBAL.APPLY' | translate}}</button>
    </div>
</ng-template>
