import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppApiUrls} from '../app.api.urls';
import {HomeInterface} from './home.interface';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }

  get(): Observable<HomeInterface[]> {
    return this.http.get<HomeInterface[]>(AppApiUrls.moduleMainPage(), {
      params: {
        city_id: localStorage.getItem('city'),
        prof_id: localStorage.getItem('profs')
      }
        }
    );
  }
}
