import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    OnChanges,
    OnInit,
    SimpleChanges,
    TemplateRef
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../@shared/login-page/auth.service';
import {NavbarService} from '../@shared/navbar/navbar.service';
import {SpeedTestService} from 'ng-speed-test';

import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {DevToolsComponent} from '../dev-tools/dev-tools.component';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {FlatTreeControl} from '@angular/cdk/tree';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Unsubscribe} from '../@shared/unsubscribe';
import {News, NewsItem} from '../notification/interface/notification.interface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from "../../environments/environment";

interface FoodNode {
    id: number;
    img: string;
    name_ua: string;
    parent_id: number;
    type: number;
    url: string;
    url_ang: string;
    name: string;
    children?: FoodNode[];
}

interface ExampleFlatNode {
    children: boolean;
    expandable: boolean;
    name: string;
    level: number;
    url_ang: string;
}

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent extends Unsubscribe implements OnInit{
    public isCollapsed = true;
    public UserName = localStorage.getItem('name');
    public UserImg = localStorage.getItem('image');
    public UserProfs: string = localStorage.getItem('profs');
    public UserPlace: string = localStorage.getItem('place');
    userCity: string = localStorage.getItem('city-name');
    treeControl;
    treeFlattener;
    dataSource;
    // searchControl = new FormControl();
    originalTreeData: any[];
    showNotifications = false;
    news: NewsItem;
    newsInfo: any;
    isUnreadFilterActive = false;
    disableDropdown = false;
    transformer = (node: FoodNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level,
            id: node.id,
            img: node.img,
            name_ua: node.name_ua,
            parent_id: node.parent_id,
            type: node.type,
            url: node.url,
            url_ang: node?.url_ang,
        };
    }
    searchQuery: string = '';
    filteredTreeData: any[] = [];


    constructor(public translate: TranslateService,
                public Auth: AuthService,
                public NavbarService: NavbarService,
                private speedTestService: SpeedTestService,
                private _bottomSheet: MatBottomSheet,
                private modalService: NgbModal,
                private route: ActivatedRoute,
                private router: Router
    ) {
        super();
        translate.addLangs(['ru', 'ua', 'en']);
        translate.setDefaultLang('ua');
        translate.use(localStorage.getItem('lang') || 'ua');
        this.treeControl = new FlatTreeControl<ExampleFlatNode>(
            node => node.level, node => node.expandable);
        this.treeFlattener = new MatTreeFlattener(
            this.transformer, node => node.level, node => node.expandable, node => node.children);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        setTimeout(() => {
            this.speedTest();
        }, 15000);
    }

    ngOnInit(): void {
        this.get();
        this.getCityInfo();
        this.NavbarService.getAllPlaces();
        this.getNews();
        this.checkPathname();
        this.onChange();
    }

   onChange(): void{
       this.router.events.pipe(takeUntil(this.$destroy))
           .subscribe(event => {
           if (event instanceof NavigationEnd) {
               this.checkPathname();
           }
       });
   }


    checkPathname(): void {
        this.disableDropdown = window.location.pathname !== '/';
    }

    getCityInfo(): void {
        this.NavbarService.getCityInfo().subscribe({
            next: value => {
                localStorage.setItem('cityInfo', JSON.stringify(value.data));
            }
        });
    }

    changeLang(langSelect, lang): void {
        localStorage.setItem('lang', lang);
        this.translate.use(langSelect.value);
        window.location.reload();
    }

    get(): void {
        this.NavbarService.fetchModels()
            .subscribe({
                next: value => {
                    this.dataSource.data = value;
                    this.originalTreeData = [...this.dataSource.data];
                    this.filteredTreeData = [...this.dataSource.data];
                },
            });
    }

    hasChild = (_: number, node: ExampleFlatNode) => node.expandable;


    speedTest = () => {
        this.speedTestService.getMbps(
            {
                iterations: 10,
                file: {
                    path: 'background.jpg',
                    size: 147800
                } as any,
                retryDelay: 1500,
            }
        ).subscribe(
            (speed) => {
                setTimeout(() => {
                    this.speedTest();
                }, 3e6);
                console.log('Your speed is ' + speed);
            }
        );
    }

    openBottomSheet(): void {
        this._bottomSheet.open(DevToolsComponent);
    }

    toggleNotifications(): void {
        this.showNotifications = !this.showNotifications;
        this.isUnreadFilterActive = false;
    }

    getNews = (): void => {
        this.NavbarService.getNews()
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                next: value => {
                    this.news = value.data;
                }
            });
    }

    getNewsById(id: number): void {
        this.NavbarService
            .getNewsByID(id)
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                next: (value) => {
                    this.newsInfo = value.data;
                }
            });
    }

    create(id: number, modal: TemplateRef<News>): void {
        this.modalService.open(modal, {size: 'lg'});
        this.getNewsById(id);
    }

    close(): void {
        this.modalService.dismissAll();
    }

    openAttachment(url: string): void {
        const fullUrl = environment.url + url;
        window.open(fullUrl, '_blank');
    }

    getFileName(url: string): string {
        const parts = url.split('/');
        return parts[parts.length - 1];
    }

    checkedNews(id: number, modal: TemplateRef<News>, confirmation: number): void {
        const actionOpened = {
            user_id: localStorage.getItem('id'),
            news_id: id,
            action: 'opened'
        };

        if (confirmation === 0) {
            this.create(id, modal);
            this.sendNewsAction(actionOpened);
        } else if (confirmation === 1) {
            this.create(id, modal);
        }
    }

    sendNewsAction(body: any): void {
        this.NavbarService.checkedNews(body)
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                complete: () => {
                    this.getNews();
                }
            });
    }

    actionConfirmed(id: number): void {
        const actionConfirmed = {
            user_id: localStorage.getItem('id'),
            news_id: id,
            action: 'confirmed'
        };
        this.sendNewsAction(actionConfirmed);
    }

    toggleUnreadFilter(event?: Event): void {
            event.stopPropagation();
            this.isUnreadFilterActive = !this.isUnreadFilterActive;

    }

    showNoUnreadNotifications(): boolean {
        if (this.news.unread === 0) {
            return this.isUnreadFilterActive ? this.news?.news.every(item => item.confirmation.length === 0) : true;
        }
    }

    replaceN(): string {
        return this.newsInfo?.description.replaceAll('\n', '<br>');
    }

    filterTree(query: string): void {
        if (!query) {
            this.filteredTreeData = this.deepCopy(this.originalTreeData);
        } else {
            const copy = this.deepCopy(this.originalTreeData);
            this.filteredTreeData = copy.filter(node => this.filterNode(node, query.toLowerCase()));
        }
        this.dataSource.data = this.filteredTreeData;
    }

    filterNode(node: any, query: string): boolean {
        if (node.name.toLowerCase().includes(query) && !node.children) {
            return true;
        }
        if (node.children) {
            node.children = node.children.filter((child: any) => this.filterNode(child, query));
            return node.children.length > 0;
        }
        return false;
    }

    // Вспомогательный метод для создания глубокой копии данных
    deepCopy(data: any) {
        return JSON.parse(JSON.stringify(data));
    }

    clearSearch(): void {
        this.searchQuery = '';
        this.filterTree('');
    }

}
